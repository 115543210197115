import "./set-public-path.js";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { property } from "lodash";

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  setPublicPath(publicPath: string) {
    __webpack_public_path__ = publicPath;
  },
  childAppName: "prototypes-ui",
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: () =>
    import(
      /* webpackChunkName: "prototypes-ui-root" */ "./root.component"
    ).then(property("default")),
  domElementGetter,
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];
export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("prototypes-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "prototypes-ui");
    document.body.appendChild(el);
  }

  return el;
}
